<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Payment: </span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="quote">
              <tbody>
                  <tr v-for="(v, k, index) in quote" :key="index" v-if="k !== 'id' && k !== 'user_id' && k !== 'claim_id' && k !== 'options' && k !== 'status_display' && k !== 'claim' && k !== 'payment_to_id'">
                      <th v-if="k === 'status_display'">Status</th>
                      <th v-else-if="k === 'reference'">Claim Reference</th>
                      <th v-else>{{ k|capitalize }}</th>
                      <td v-if="k === 'status'">
                        <b-form-select v-model="model.status" :options="options.statuses"  :state="validateState(k)"></b-form-select>
                      </td>
                      <td v-else-if="k === 'payment_details'">
                        <p v-for="(pk,pv,  pi) in quote.payment_details" :key="pi"><b class="text-muted">{{pv|capitalize}}:</b> {{ pk }}</p>
                      </td>
                    <td v-else>{{ v }}</td>
                  </tr>
              </tbody>
          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="pull-left">Back</b-button>
          <b-button @click="Submit" variant="outline-success" class="float-right mr-2">Submit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
  import axios from '../../../shared/axios/auth'
  import { validationMixin } from 'vuelidate'
  import { required } from "vuelidate/lib/validators";

  export default {
    name: 'Quote',
    props: {
      caption: {
        type: String,
        default: 'Quote Reference '
      },
    },
    mixin: [validationMixin],
    data: () => {
      return {
        quote: null,
        model: {
          status: null
        },
        options: {}
      }
    },
    validations() {
      return {
        model: {
          status: {
              required
          }
        }
      }
    },
    methods: {
      validateState(name) {

        if (this.$v.model[name]) {
          const {$dirty, $error} = this.$v.model[name];
          return $dirty ? !$error : null;
        }
      },
      goBack() {
        this.$router.go(-1)
      },
      getQuote() {
          let url = '/payments/' + this.$route.params.id + '/';

        axios.get(url).then(
          response => {
            this.quote = response.data
            this.model.status = response.data.status
            this.options.statuses = response.data.options.statuses
        }
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.quote
      },
      Submit() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {
          return
        } else {
          // Valid

          let url = '/payments/' + this.quote.id + '/'

          var post_data = {
            'status': this.model.status
          }

          axios.patch(url, post_data).then(
            response => {
              console.log(response.data)
              this.error = false;
              this.$router.replace({ name: 'Payment', params: { id: this.quote.id } })
            }
          ).catch(error => {
              console.log('post error');
              console.log(error.response);

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
          })

        }
      }
    },
    mounted() {
      this.getQuote()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
